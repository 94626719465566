import React from "react"

//Utils
import { useWindowSize } from "components/hooks/hooks"
import Helmet from "react-helmet"
import Seo from "components/seo"
import { TokenValidation } from "lib/utils/token-validation"
import { useRouter } from "next/router"
import nookies from "nookies"

//Layout
import Layout from "components/layout"

//Components
import SignInComponents from "components/pages/signin"
import { toastHandling } from "lib/utils/toast-handling"

const SignInPage = () => {
  //Utils
  const { width } = useWindowSize()
  const Router = useRouter()

  React.useEffect(() => {
    if (Router?.query?.redirect === "signup")
      setTimeout(() => {
        toastHandling({
          message: "Thank you for registering!",
          status: "success",
        })
      }, 700)
  }, [])

  return (
    <>
      <Seo title="Sign In" />
      <Helmet>
        <body className="bd-login" />
      </Helmet>
      <Layout secondary noFooter navTheme="navbar-black">
        <SignInComponents />
      </Layout>
    </>
  )
}

export default SignInPage

export const getServerSideProps = async (ctx) => {
  let accessToken = await nookies.get(ctx)[`${process.env.ACCESS_TOKEN}`]

  if (!accessToken) {
    return { props: {} }
  } else {
    return { redirect: { destination: "/", permanent: false } }
  }
}

// export const getServerSideProps = async (ctx) => {
//   let { user_status, token } = await TokenValidation(ctx)
//   if (user_status) {
//     return {
//       redirect: {
//         permanent: false,
//         destination: "/",
//       },
//     }
//   }
//   return { props: { token: null, status: user_status } }
// }
